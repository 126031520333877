import React from "react";
import { useEffect, useState } from "react";
import imageGeneratorService from "../services/imageGeneratorService";
import NavBar from "../components/NavBar";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/esm/Row";
import "../css/generalcard.css";

function Privacy() {
	const [imageResult, setImageResult] = useState({
		imageUrl: "",
		createdBy: "",
		linkToProfile: "",
	});
	const onImageSuccess = (response) => {
		const randomIndex = Math.floor(Math.random() * response.results.length);

		const randomImage = response.results[randomIndex];

		const imageUrl = randomImage.urls.full;

		setImageResult((prevState) => {
			let ps = { ...prevState };
			ps.imageUrl = imageUrl;
			ps.createdBy = randomImage.user.name;
			ps.linkToProfile = randomImage.user.links.html;
			return ps;
		});
	};

	const onImageFailed = (error) => {
		console.log(error);
	};

	useEffect(() => {
		imageGeneratorService
			.getBackGroundImage()
			.then(onImageSuccess)
			.catch(onImageFailed);
	}, []);

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${imageResult.imageUrl})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "100vh",
				}}
			>
				<NavBar></NavBar>
				<Container className="px-5">
					<Row className="pt-4">
						<Card className="h-100 bg-black opacity-75">
							<Card.Body style={{ maxHeight: "90vh", overflowY: "auto" }}>
								<Card.Title className="d-flex justify-content-around">
									<h1 className="border-bottom">Privacy Policy</h1>
								</Card.Title>
								<Card.Text className="text-center">
									<h4>Workout Tab is owned and operated by:</h4>

									<p>Loyal Storage, Inc</p>
									<p>668 E 12225 S, Ste. 204</p>
									<p>Draper, UT, 84020</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										The privacy of our visitors is of high importance to us.
										This privacy policy document outlines the types of personal
										information is received and collected by this website and
										how it is used.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Log Files</h3>
									<p>
										Like many other Web sites, Workout Tab makes use of log
										files. The information inside the log files includes
										internet protocol ( IP ) addresses, type of browser,
										Internet Service Provider ( ISP ), date/time stamp,
										referring/exit pages, and number of clicks to analyze
										trends, administer the site, track user's movement around
										the site, and gather demographic information. IP addresses,
										and other such information are not linked to any information
										that is personally identifiable.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Cookies and Web Beacons</h3>
									<p>
										Workout Tab does use cookies to store information about
										visitors preferences, record user-specific information on
										which pages the user access or visit, customize Web page
										content based on visitors browser type or other information
										that the visitor sends via their browser.
									</p>
									<p>
										Some of our advertising partners may use cookies and web
										beacons on our site.
									</p>
									<p>
										These third-party ad servers or ad networks use technology
										to the advertisements and links that appear on this website
										send directly to your browsers. They automatically receive
										your IP address when this occurs. Other technologies ( such
										as cookies, JavaScript, or Web Beacons ) may also be used by
										the third-party ad networks to measure the effectiveness of
										their advertisements and / or to personalize the advertising
										content that you see.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										Workout Tab has no access to or control over these cookies
										that are used by third-party advertisers.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										You should consult the respective privacy policies of these
										third-party ad servers for more detailed information on
										their practices as well as for instructions about how to
										opt-out of certain practices. Our privacy policy does not
										apply to, and we cannot control the activities of, such
										other advertisers or web sites.
									</p>
									<p>
										If you wish to disable cookies, you may do so through your
										individual browser options. More detailed information about
										cookie management with specific web browsers can be found at
										the browsers' respective websites.
									</p>
									<p>
										Some of the web search services provided herein are provided
										by providers of third party web search services. For
										information on the web search services data collection,
										please visit the end user privacy statements of our 3rd
										party web search services providers listed below, links for
										which are updated from time to time and at any successor
										locations privacy.microsoft.com
									</p>
									<p>
										The Microsoft Privacy Statement explains what personal data
										Microsoft collects and how the company uses it.
									</p>
									<p>
										Please also visit https://policies.google.com/privacy for
										privacy statements related to services provided on this
										website by Google
									</p>
								</Card.Text>
							</Card.Body>
						</Card>
					</Row>
				</Container>
				<a
					href={imageResult.linkToProfile}
					style={{
						position: "absolute",
						bottom: "10px",
						left: "10px",
						color: "white",
						textDecoration: "underline",
					}}
				>
					Credit to: {imageResult.createdBy}
				</a>
			</div>
		</>
	);
}

export default Privacy;
