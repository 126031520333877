import React from "react";
import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import imageGeneratorService from "../services/imageGeneratorService";
import NavBar from "../components/NavBar";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/esm/Row";
import "../css/generalcard.css";

function ContactUs() {
	const [imageResult, setImageResult] = useState({
		imageUrl: "",
		createdBy: "",
		linkToProfile: "",
	});
	const onImageSuccess = (response) => {
		const randomIndex = Math.floor(Math.random() * response.results.length);

		const randomImage = response.results[randomIndex];

		const imageUrl = randomImage.urls.full;

		setImageResult((prevState) => {
			let ps = { ...prevState };
			ps.imageUrl = imageUrl;
			ps.createdBy = randomImage.user.name;
			ps.linkToProfile = randomImage.user.links.html;
			return ps;
		});
	};

	const onImageFailed = (error) => {
		console.log(error);
	};

	useEffect(() => {
		imageGeneratorService
			.getBackGroundImage()
			.then(onImageSuccess)
			.catch(onImageFailed);
	}, []);

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${imageResult.imageUrl})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "100vh",
				}}
			>
				<NavBar></NavBar>
				<Container className="h-25"></Container>
				<Container className="px-5">
					<Row>
						<Card className="h-100 bg-black opacity-75">
							<Card.Body>
								<Card.Title className="d-flex justify-content-around">
									<h1 className="border-bottom">Contact Us</h1>
								</Card.Title>
								<Card.Text className="text-center">
									<p>Loyal Storage, Inc</p>
									<p>668 E 12225 S, Ste. 204</p>
									<p>Draper, UT, 84020</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										By installing and using Workout Tab you agree to the
										Terms of Use and Privacy Policy.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										Please review our
										<Link to="/privacy">Privacy Policy</Link> for details
										regarding the information we collect when you install and
										use the extension. You can uninstall the program any time.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<p>
										Please check our
										<Link to="/terms">Terms of Service</Link>
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									For any questions or concerns you can email us at{" "}
									<a href="mailto:loyalstorageservices@gmail.com">loyalstorageservices@gmail.com</a>
								</Card.Text>
							</Card.Body>
						</Card>
					</Row>
				</Container>
				<a
					href={imageResult.linkToProfile}
					style={{
						position: "absolute",
						bottom: "10px",
						left: "10px",
						color: "white",
						textDecoration: "underline",
					}}
				>
					Credit to: {imageResult.createdBy}
				</a>
			</div>
			<Outlet />
		</>
	);
}

export default ContactUs;
