import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import Ext from "./pages/Ext.jsx"
import Install from "./pages/Install.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import Privacy from "./pages/Privacy.jsx";
import Terms from "./pages/Terms.jsx";
import Uninstall from "./pages/Uninstall.jsx";
import ContactUs from "./pages/ContactUs.jsx";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Ext />}/>
        <Route path="/install" element={<Install />}/>
        <Route path="/aboutus" element={<AboutUs />}/>
        <Route path="/contactus" element={<ContactUs />}/>
        <Route path="/privacy" element={<Privacy />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/uninstall" element={<Uninstall />}/>
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);