import {useState, useEffect, React} from "react";
import imageGeneratorService from "../services/imageGeneratorService";
import NavBar from "../components/NavBar";
import {ToastContainer} from "react-toastify";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import Time from "../components/Time";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "../css/dropdown.css";

function Home() {
    const [imageResult, setImageResult] = useState({
        imageUrl: "",
        createdBy: "",
        linkToProfile: "",
    });

    useEffect(() => {
        imageGeneratorService
            .getBackGroundImage()
            .then(onImageSuccess)
            .catch(onImageFailed);
    }, []);
    const onImageSuccess = (response) => {
        const randomIndex = Math.floor(Math.random() * response.results.length);

        const randomImage = response.results[randomIndex];

        const imageUrl = randomImage.urls.full;

        setImageResult((prevState) => {
            let ps = {...prevState};
            ps.imageUrl = imageUrl;
            ps.createdBy = randomImage.user.name;
            ps.linkToProfile = randomImage.user.links.html;
            return ps;
        });
    };

    const onImageFailed = (error) => {
        console.log(error);
    };

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${imageResult.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "100vh",
                }}
            >
                <NavBar
                    user={imageResult.createdBy}
                    userLink={imageResult.linkToProfile}
                ></NavBar>
                <Container className="h-25"></Container>
                <Container className="px-5">
                    <Row className="d-flex justify-content-center">
                        <div className="container">
                            <div className="row align-items-center my-5">
                                <div className="col-12 custom-card">
                                    <h1 className="font-weight-light">
                                        Workout Tab
                                    </h1>
                                </div>
                            </div>
                            <div className="container">
                                <div className="custom-card">
                                    <h4>
                                        By clicking "Add to chrome", I accept and agree to installing WorkoutTab Search
                                        Extension and setting New Tab to WorkoutTab Search that
                                        provided by the service, Terms of Use and Privacy Policy.
                                    </h4>
                                    <div className="col-12 text-center">
                                        <button className="btn btn-primary btn-lg"
                                                onClick={() => window.location.href = "https://chromewebstore.google.com/detail/workouttab/ggllcihdofhhbadkddodmbmdmfofbeba"}>
                                            Add to Chrome
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>

                <a
                    href={imageResult.linkToProfile}
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        color: "white",
                        textDecoration: "underline",
                    }}
                >
                    Credit to: {imageResult.createdBy}
                </a>
            </div>
            <ToastContainer/>
        </>
    );
}

export default Home;
