import axios from "axios";

const query = "workout";
const clientId = "1l_yMFo4knWKhd5p0Sfsu3SwboZp5KyNLUJ5dObqgIk";
const endpoint = `https://api.unsplash.com/search/photos?query=${query}&client_id=${clientId}`;

const getBackGroundImage = () => {
	const config = {
		method: "GET",
		url: `${endpoint}`,
		withCredentials: false,
		crossdomain: true,
		headers: { "Content-Type": "application/json" },
	};
	return axios(config)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			console.log(error);
		});
};

const imageGeneratorService = {
	getBackGroundImage,
};

export default imageGeneratorService;
