import React from "react";
import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import imageGeneratorService from "../services/imageGeneratorService";
import NavBar from "../components/NavBar";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/esm/Row";
import "../css/generalcard.css";

function Terms() {
	const [imageResult, setImageResult] = useState({
		imageUrl: "",
		createdBy: "",
		linkToProfile: "",
	});
	const onImageSuccess = (response) => {
		const randomIndex = Math.floor(Math.random() * response.results.length);

		const randomImage = response.results[randomIndex];

		const imageUrl = randomImage.urls.full;

		setImageResult((prevState) => {
			let ps = { ...prevState };
			ps.imageUrl = imageUrl;
			ps.createdBy = randomImage.user.name;
			ps.linkToProfile = randomImage.user.links.html;
			return ps;
		});
	};

	const onImageFailed = (error) => {
		console.log(error);
	};

	useEffect(() => {
		imageGeneratorService
			.getBackGroundImage()
			.then(onImageSuccess)
			.catch(onImageFailed);
	}, []);

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${imageResult.imageUrl})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "100vh",
				}}
			>
				<NavBar></NavBar>
				<Container className="px-5">
					<Row className="pt-4">
						<Card className="h-100 bg-black opacity-75">
							<Card.Body style={{ maxHeight: "90vh", overflowY: "auto" }}>
								<Card.Title className="d-flex justify-content-around">
									<h1 className="border-bottom">Terms and Conditions</h1>
								</Card.Title>
								<Card.Text className="text-center">
									<h4>Workout Tab is owned and operated by:</h4>

									<p>Loyal Storage, Inc</p>
									<p>668 E 12225 S, Ste. 204</p>
									<p>Draper, UT, 84020</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Terms</h3>
									<p>
										By clicking "Add to chrome", I accept and agree to
										installing Workout Tab Search Extension and setting New tab
										and Default search to Bing that provided by the service,
										Terms of Use and Privacy Policy.
									</p>
									<p>
										By accessing this web site, you are agreeing to be bound by
										these web site Terms and Conditions of Use, all applicable
										laws and regulations, and agree that you are responsible for
										compliance with any applicable local laws. If you do not
										agree with any of these terms, you are prohibited from using
										or accessing this site. The materials contained in this web
										site are protected by applicable copyright and trade mark
										law.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Use License</h3>
									<p>
										Permission is granted to temporarily download one copy of
										the materials (information or software) on Workout Tab's web
										site for personal, non-commercial transitory viewing only.
										This is the grant of a license, not a transfer of title, and
										under this license you may not:
									</p>
									<ul style={{ listStyleType: "none" }}>
										<li className="li-style">Modify or copy the materials</li>
										<li className="li-style">
											Use the materials for any commercial purpose, or for any
											public display (commercial or non-commercial)
										</li>
										<li className="li-style">
											Attempt to decompile or reverse engineer any software
											contained on Workout Tab's web site
										</li>
										<li className="li-style">
											Remove any copyright or other proprietary notations from
											the materials
										</li>
										<li className="li-style">
											Transfer the materials to another person or "mirror" the
											materials on any other server
										</li>
									</ul>
									<p>
										This license shall automatically terminate if you violate
										any of these restrictions and may be terminated by Workout
										Tab at any time. Upon terminating your viewing of these
										materials or upon the termination of this license, you must
										destroy any downloaded materials in your possession whether
										in electronic or printed format.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Disclaimer</h3>
									<p>
										The materials on Workout Tab's web site are provided "as
										is". Workout Tab makes no warranties, expressed or implied,
										and hereby disclaims and negates all other warranties,
										including without limitation, implied warranties or
										conditions of merchantability, fitness for a particular
										purpose, or non-infringement of intellectual property or
										other violation of rights. Further, WorkoutTab does not
										warrant or make any representations concerning the accuracy,
										likely results, or reliability of the use of the materials
										on its Internet web site or otherwise relating to such
										materials or on any sites linked to this site.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Limitations</h3>
									<p>
										In no event shall Workout Tab or its suppliers be liable for
										any damages (including, without limitation, damages for loss
										of data or profit, or due to business interruption,) arising
										out of the use or inability to use the materials on Workout
										Tab's Internet site, even if Workout Tab or a Workout Tab
										authorized representative has been notified orally or in
										writing of the possibility of such damage. Because some
										jurisdictions do not allow limitations on implied
										warranties, or limitations of liability for consequential or
										incidental damages, these limitations may not apply to you.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Revisions and Errata</h3>
									<p>
										The materials appearing on Workout Tab's web site could
										include technical, typographical, or photographic errors.
										Workout Tab does not warrant that any of the materials on
										its web site are accurate, complete, or current. Workout Tab
										may make changes to the materials contained on its web site
										at any time without notice. Workout Tab does not, however,
										make any commitment to update the materials.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Links</h3>
									<p>
										Workout Tab has not reviewed all of the sites linked to its
										Internet web site and is not responsible for the contents of
										any such linked site. The inclusion of any link does not
										imply endorsement by Workout Tab of the site. Use of any
										such linked web site is at the user's own risk.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">
										Site Terms of Use Modifications
									</h3>
									<p>
										Workout Tab may revise these terms of use for its web site
										at any time without notice. By using this web site you are
										agreeing to be bound by the then current version of these
										Terms and Conditions of Use.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Governing Law</h3>
									<p>
										Any claim relating to Workout Tab's web site shall be
										governed by the laws of the State of Texas without regard to
										its conflict of law provisions.
									</p>
								</Card.Text>
								<Card.Text className="text-center">
									<h3 className="border-bottom">Third Parties</h3>
									<p>
										Workout Tab makes use of third party api's to perform
										various functions of the website. You agree to the terms of
										service defined by the following third parties in addition
										to the terms specified here. YouTube Terms of Service-
										Please visit https://www.youtube.com/t/terms to review.
									</p>
								</Card.Text>
							</Card.Body>
						</Card>
					</Row>
				</Container>
				<a
					href={imageResult.linkToProfile}
					style={{
						position: "absolute",
						bottom: "10px",
						left: "10px",
						color: "white",
						textDecoration: "underline",
					}}
				>
					Credit to: {imageResult.createdBy}
				</a>
			</div>
			<Outlet />
		</>
	);
}

export default Terms;
