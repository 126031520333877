import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Outlet, Link } from "react-router-dom";
import "../css/navbar.css";

function NavBar() {
	return (
		<>
			<Navbar className="bg-black opacity-75">
				<Container className="justify-content-center">
					<Navbar.Brand>
						<Link className="mx-2" sty to="/">
							Home
						</Link>
						<Link className="mx-2" to="/terms">
							Terms
						</Link>
						<Link className="mx-2" to="/privacy">
							Privacy Policy
						</Link>
						<Link className="mx-2" to="/aboutus">
							About Us
						</Link>
						<Link className="mx-2" to="/contactus">
							Contact Us
						</Link>
						<Link className="mx-2" to="/uninstall">
							Uninstall
						</Link>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<Outlet />
		</>
	);
}

export default NavBar;
